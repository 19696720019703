import {makeStyles} from '@material-ui/core'
import clsx from 'clsx'
import {GatsbyLinkProps, Link as GatsbyLink} from 'gatsby'
import React from 'react'
import {pxToRem} from '../../../lib'

export interface LinkProps extends Omit<GatsbyLinkProps<any>, 'ref' | 'to'> {
  to: string
  color?: 'primary' | 'secondary'
}

const useStyles = makeStyles(theme => ({
  link: {
    fontFamily: theme.typography.fontFamily,
    fontSize: pxToRem(18),
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: pxToRem(20),
    },
  },
  activeLink: {
    fontWeight: theme.typography.fontWeightBold,
  },
  primaryLink: {
    color: theme.palette.primary.main,
  },
  secondaryLink: {
    color: theme.palette.secondary.main,
  },
}))

export const Link: React.FC<LinkProps> = ({
  className,
  activeClassName,
  color = 'primary',
  ...props
}) => {
  const classes = useStyles()

  return (
    <GatsbyLink
      className={clsx(
        classes.link,
        className,
        color && color === 'primary'
          ? classes.primaryLink
          : classes.secondaryLink,
      )}
      activeClassName={clsx(classes.activeLink, activeClassName)}
      {...props}
    />
  )
}

export default Link

import {
  AppBar,
  Container,
  makeStyles,
  Typography,
  withStyles,
} from '@material-ui/core'
import Img from 'gatsby-image'
import React from 'react'
import {pxToRem} from '../../../lib'
import {GatsbyImage} from '../../../types'
import {Link} from '../../atoms'

export interface HeaderProps {
  logo: GatsbyImage
  title?: string
}

const StyledAppBar = withStyles(theme => ({
  root: {
    paddingTop: theme.spacing(2.5),
    paddingBottom: theme.spacing(2.5),
  },
}))(AppBar)

const StyledTitle = withStyles(theme => ({
  root: {
    fontWeight: theme.typography.fontWeightBold,
    [theme.breakpoints.down('xs')]: {
      fontSize: pxToRem(18),
    },
  },
}))(Typography)

const useStyles = makeStyles(theme => ({
  logo: {
    width: 32,
    height: 32,
    marginRight: theme.spacing(),
  },
  link: {
    color: theme.palette.text.primary,
    textDecoration: 'none !important',
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
}))

export const Header: React.FC<HeaderProps> = ({logo, title}) => {
  const classes = useStyles()

  return (
    <StyledAppBar elevation={0} position="absolute" color="transparent">
      <Container>
        <Link to="/" className={classes.link}>
          <Img fluid={logo.childImageSharp.fluid} className={classes.logo} />
          {title && <StyledTitle>{title}</StyledTitle>}
        </Link>
      </Container>
    </StyledAppBar>
  )
}

export default Header

import {Container, withStyles} from '@material-ui/core'

export const PageContainer = withStyles(theme => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
  },
}))(Container)

export default PageContainer

import {Grid, makeStyles, withStyles} from '@material-ui/core'
import Img, {GatsbyImageFluidProps} from 'gatsby-image'
import * as React from 'react'

export interface MobileDotBackgroundGridProps {
  fluidImage: GatsbyImageFluidProps
  alt?: string
}

const StyledGrid = withStyles(theme => ({
  root: {
    display: 'none',
    height: 175,
    boxSizing: 'initial',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
}))(Grid)

const useStyles = makeStyles(theme => ({
  image: {
    maxWidth: 400,
    maxHeight: 175,
    marginTop: theme.spacing(2),
    marginLeft: 'auto',
    '& img': {
      objectPosition: 'top center !important',
    },
  },
}))

export const MobileDotBackgroundGrid: React.FC<MobileDotBackgroundGridProps> = ({
  fluidImage,
  alt,
}) => {
  const classes = useStyles()

  return (
    <StyledGrid item>
      <Img fluid={fluidImage.fluid} className={classes.image} alt={alt} />
    </StyledGrid>
  )
}

export default MobileDotBackgroundGrid

import {Theme} from '@material-ui/core'

export const pageLayoutStyles = (theme: Theme) => ({
  alignItems: 'center',
  ['@media screen and (max-height: 685px) and (max-width: 600px), (max-height: 500px)']: {
    paddingTop: theme.spacing(6),
    alignItems: 'flex-start',
  },
})

export default pageLayoutStyles

import {Typography, TypographyProps} from '@material-ui/core'
import React from 'react'

export interface HeadingProps
  extends Omit<TypographyProps, 'variant' | 'component'> {
  variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  component?: string
}

export const Heading: React.FC<HeadingProps> = ({variant = 'h1', ...props}) => {
  return <Typography variant={variant} {...props} />
}

export default Heading

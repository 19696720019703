import {SEO, SEOProps} from '@hypesync/seo'
import {Box, BoxProps, makeStyles, withStyles} from '@material-ui/core'
import {graphql, StaticQuery} from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import React from 'react'
import {Footer, Header} from '../../components'
import '../../styles/layout.css'
import pageLayoutStyles from '../../styles/pageLayout'

export interface PageLayoutProps
  extends SEOProps,
    Pick<BoxProps, 'className' | 'alignItems'> {}

const StyledBox = withStyles(theme => ({
  root: {
    display: 'flex',
    height: '100%',
    ...pageLayoutStyles(theme),
    ['@media screen and (max-height: 865px) and (max-width: 600px), (max-height: 500px)']: {
      paddingTop: theme.spacing(3),
      height: 'auto',
    },
  },
}))(Box)

const useStyles = makeStyles(theme => ({
  backgroundImage: {
    height: '100% !important',
    [theme.breakpoints.down('sm')]: {
      '&:before': {
        backgroundImage: 'none !important',
        opacity: 0,
      },
      '&:after': {
        backgroundImage: 'none !important',
        opacity: 0,
      },
    },
  },
}))

export const PageLayout: React.FC<PageLayoutProps> = ({
  alignItems = 'flex-start',
  className,
  children,
  ...props
}) => {
  const classes = useStyles()

  return (
    <StaticQuery
      query={graphql`
        query PageLayoutQuery {
          logo: file(relativePath: {eq: "icon.png"}) {
            childImageSharp {
              fluid(maxWidth: 60) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
          backgroundImage: file(relativePath: {eq: "dot-drawing.png"}) {
            childImageSharp {
              fluid(maxWidth: 2560) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      `}
      render={data => (
        <BackgroundImage
          Tag="section"
          fluid={data.backgroundImage.childImageSharp.fluid}
          className={classes.backgroundImage}
          alt="Opaque dot pattern"
          critical
        >
          <StyledBox alignItems={alignItems} className={className}>
            <Header logo={data.logo} title={props.siteName} />

            {children}

            <SEO {...props} />
          </StyledBox>

          <Footer />
        </BackgroundImage>
      )}
    />
  )
}

export default PageLayout

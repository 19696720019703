import {
  Box,
  Container,
  makeStyles,
  Typography,
  withStyles,
} from '@material-ui/core'
import * as React from 'react'
import {pxToRem} from '../../../lib'
import {Link} from '../../atoms'

export interface FooterProps {}

const StyledBox = withStyles(theme => ({
  root: {
    position: 'relative',
    top: 0,
    width: '100%',
    minHeight: 135,
    backgroundColor: theme.palette.text.primary,
    zIndex: 10,
    marginTop: theme.spacing(),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      minHeight: 96,
      paddingBottom: theme.spacing(5),
    },
  },
}))(Box)

const StyledContainer = withStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexFlow: 'row wrap',
    justifyContent: 'center',
    [theme.breakpoints.up(725)]: {
      justifyContent: 'space-between',
    },
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
  },
}))(Container)

const StyledLinkBox = withStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexFlow: 'row wrap',
    '& a': {
      [theme.breakpoints.up('md')]: {
        margin: theme.spacing(0, 1.25),
      },
      [theme.breakpoints.down('sm')]: {
        margin: theme.spacing(0, 1),
      },
    },
  },
}))(Box)

const StyledLightText = withStyles(theme => ({
  root: {
    color: theme.palette.common.white,
    [theme.breakpoints.down('xs')]: {
      fontSize: pxToRem(18),
      marginBottom: theme.spacing(),
    },
  },
}))(Typography)

const useStyles = makeStyles(theme => ({
  link: {
    fontSize: pxToRem(16),
  },
  activeLink: {
    fontWeight: theme.typography.fontWeightRegular,
  },
}))

export const Footer: React.FC<FooterProps> = () => {
  const classes = useStyles()

  return (
    <StyledBox>
      <StyledContainer>
        <StyledLightText>
          Copyright &copy; 2021 HypeSync. All rights reserved.
        </StyledLightText>

        <StyledLinkBox>
          <Link
            color="secondary"
            to="/impressum"
            className={classes.link}
            activeClassName={classes.activeLink}
          >
            Impressum
          </Link>

          <Link
            color="secondary"
            to="/privacy-policy"
            className={classes.link}
            activeClassName={classes.activeLink}
          >
            Privacy Policy
          </Link>

          <Link
            color="secondary"
            to="/cookie-policy"
            className={classes.link}
            activeClassName={classes.activeLink}
          >
            Cookie Policy
          </Link>
        </StyledLinkBox>
      </StyledContainer>
    </StyledBox>
  )
}

export default Footer
